import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import { isRetrieveUserCompleted, selectUserPermissions } from '@core/store/selectors/core.selectors';
import { map, skipWhile, take } from 'rxjs';
import { concatLatestFrom } from '@ngrx/operators';
import { PermissionEnum } from '@core/enums/permission.enum';

export const sessionUserPermissionsResolver: ResolveFn<PermissionEnum[]> = (route, state) => {
  const store: Store = inject(Store);

  return store.select(isRetrieveUserCompleted).pipe(
    skipWhile(isRetrieveUserCompleted => !isRetrieveUserCompleted),
    take(1),
    concatLatestFrom(() => store.select(selectUserPermissions).pipe(
      take(1),
    )),
    map(([, userPermissions]) => userPermissions)
  );
};
